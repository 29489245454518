import '../pcss/app.pcss'

// Alpine.js
import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'

Alpine.plugin(focus)

window.Alpine = Alpine
Alpine.start()
//

// Syntax highlighting
import Prism from 'prismjs'

// Home page animation
// ? Check if this is the home page
const indexHero = document.getElementById('index-hero') || false
// ? If it is, animate the banner
if (indexHero) {
	var x = 0
	setInterval(function () {
		x += 1

		document.getElementById('index-hero').style.backgroundPosition =
			'0 ' + x + 'px'
	}, 100)
}

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log('HMR')
	})
}
